export default function(props){
    let getColor = (styleKey) => {
      if ((styleKey == 'cls-1') && (props.backgroundColor)) return { fill: props.backgroundColor };
      if (props.color) return { fill: props.color };
      let defaultColors = {
        'cls-1': {
          fill: '#fff'
        },
        'cls-2': {
          fill: '#aad3ed'
        },
        'cls-3': {
          fill: '#56a7dc'
        },
        'cls-4': {
          fill: '#8d9eb3'
        },
        'cls-5': {
          fill: '#3283c5'
        },
        'cls-6': {
          fill: '#1c3d68'
        },
        'cls-7': {
          fill: '#8f9fb4'
        }
      };
      return defaultColors[styleKey];
    };
  
    return <div className={props.className} style={props.style}>
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 951.33 1080">
          <path style={getColor("cls-1")} d="m470.6,1080H0V357.13c.14.04.29.06.44.05.19,4.69.26,9.38.6,14.06.57,7.79,1.14,15.58,2.05,23.34.95,8.1,2.08,16.2,3.55,24.22,1.73,9.43,3.68,18.84,5.93,28.16,3.66,15.1,8.27,29.93,13.71,44.5,5.15,13.79,10.72,27.37,17.32,40.53,11.82,23.9,25.38,46.75,41.02,68.37,19.47,26.91,41.51,51.5,66.09,73.83,2.72,2.47,5.31,5.07,7.97,7.62.3,11.33.53,22.67.92,34,.21,6.05.44,12.12,1.1,18.14,1.68,15.29,3.4,30.59,5.5,45.82,2.7,19.59,6.96,38.89,12.35,57.89,5.48,19.33,11.77,38.44,20.31,56.68,5.58,11.91,11.25,23.83,17.69,35.29,7.76,13.81,16.47,27.08,26.74,39.23,6.35,7.51,12.62,15.11,19.32,22.3,4.67,5.01,9.89,9.54,15.07,14.04,5.56,4.84,11.31,9.46,17.09,14.05,11.14,8.85,23.26,16.23,35.71,23.02,7.11,3.87,14.58,7.11,21.97,10.45,14.65,6.61,29.88,11.58,45.4,15.65,16.21,4.26,32.64,7.43,49.33,9.1,7.67.77,15.37,1.29,23.05,1.92.12.03.24.08.34.14.02.16.03.31.03.47Z"/>
          <path style={getColor("cls-1")} d="m484.28,0h467.05v721.35c-.34-2.73-.77-5.46-.99-8.2-.56-6.88-.88-13.79-1.57-20.66-.85-8.32-1.8-16.64-3.03-24.91-2.77-18.61-6.88-36.94-12.68-54.83-4.4-13.6-9.25-27.07-14.39-40.4-3.27-8.47-7.45-16.59-11.22-24.86-6.73-12.38-13.08-24.98-20.27-37.09-17.11-28.83-37.52-55.25-60.65-79.48-10.57-11.07-22.1-21.22-33.19-31.78-.19-.54-.51-1.08-.54-1.63-.21-3.94-.39-7.87-.52-11.81-.2-5.71-.3-11.42-.53-17.13-.28-7.06-.43-14.14-1.05-21.17-1.05-11.97-2.16-23.95-3.71-35.86-2.66-20.39-6.57-40.57-11.92-60.42-3.28-12.15-7.01-24.2-10.99-36.14-7.26-21.81-16.8-42.63-28.13-62.62-15.23-26.84-33.57-51.28-56.36-72.22-6.5-5.97-13.07-11.91-20.04-17.31-26.81-20.75-56.48-36.09-88.79-46.38-25.7-8.19-51.99-13.24-78.85-15.45-2.55-.21-5.09-.65-7.63-.99Z"/>
          <path style={getColor("cls-6")} d="m907.44,547.49c3.77,8.28,7.95,16.39,11.22,24.86,5.15,13.33,9.99,26.81,14.39,40.4,5.79,17.89,9.91,36.23,12.68,54.83,1.23,8.27,2.19,16.59,3.03,24.91.7,6.87,1.01,13.77,1.57,20.66.22,2.74.66,5.47.99,8.2v18.24c-.27.15-.59.24-.8.45-13.31,13.63-26.66,27.24-39.91,40.93-11.48,11.86-22.84,23.84-34.26,35.75-8.57,8.93-17.14,17.86-25.74,26.75-6.92,7.15-13.8,14.35-20.87,21.35-19.29,19.1-38.62,38.17-58.05,57.13-8.36,8.16-16.93,16.12-25.54,24.03-10.15,9.31-20.33,18.61-30.71,27.65-11.01,9.59-21.96,19.28-33.5,28.2-16.13,12.46-32.52,24.61-49.29,36.2-11.04,7.63-22.76,14.35-34.51,20.87-7.86,4.36-16.18,8-24.53,11.36-12.86,5.17-26.09,9.17-40.16,9.3-20.97,0-41.93-.02-62.9-.03-.11-.06-.22-.11-.35-.14,0-51.8,0-103.61,0-155.41,0-1.01-.04-2.02-.06-3.03,2.4.17,4.8.41,7.21.49,9.36.33,18.69-.28,27.85-2.1,5.69-1.13,11.5-2.45,16.78-4.78,14.15-6.23,25.96-16.11,37.71-25.91,10.98-9.16,21.69-18.67,32.31-28.26,8.98-8.11,17.71-16.49,26.43-24.88,6.44-6.19,12.73-12.55,19.03-18.9,16.32-16.45,32.63-32.92,48.91-49.41,6.61-6.7,13.16-13.47,19.72-20.22,4.62-4.76,9.22-9.55,13.82-14.32,4.6-4.77,9.21-9.55,13.81-14.32,4.31-4.47,8.61-8.95,12.92-13.42,5.14-5.32,10.26-10.65,15.43-15.94,5.66-5.78,11.38-11.5,17.07-17.24,4.52-4.28,9.06-8.53,13.55-12.84,5.65-5.43,11.25-10.9,16.87-16.35,5.68-5.51,11.36-11.02,17.04-16.54,5.8-5.63,11.6-11.26,17.4-16.89,5.68-5.51,11.37-11.02,17.04-16.54,7.24-7.06,14.47-14.12,21.7-21.19,7.77-7.6,15.52-15.22,23.3-22.82.42-.41.92-.72,1.39-1.08Z"/>
          <path style={getColor("cls-6")} d="m43.6,531.99c-6.6-13.16-12.17-26.74-17.32-40.53-5.44-14.58-10.05-29.4-13.71-44.5-2.26-9.32-4.21-18.73-5.93-28.16-1.47-8.02-2.6-16.12-3.55-24.22-.91-7.75-1.48-15.55-2.05-23.34-.34-4.68-.41-9.37-.6-14.06.01-5.77.02-11.53.03-17.3,2.13-2.19,4.25-4.39,6.39-6.58,7.35-7.53,14.74-15.02,22.06-22.58,7.94-8.21,15.83-16.48,23.73-24.73,7.43-7.76,14.85-15.53,22.28-23.28,4.24-4.43,8.5-8.84,12.75-13.26,4.6-4.78,9.19-9.56,13.81-14.32,7.22-7.41,14.37-14.89,21.71-22.18,13.44-13.36,26.97-26.63,40.49-39.91,5.08-4.99,10.21-9.94,15.37-14.86,6.4-6.12,12.82-12.23,19.29-18.27,6.32-5.9,12.69-11.74,19.08-17.56,4.66-4.24,9.36-8.43,14.09-12.57,6.15-5.38,12.34-10.71,18.54-16.03,5.43-4.66,10.8-9.38,16.36-13.87,5.94-4.79,11.99-9.47,18.15-13.97,11.25-8.21,22.5-16.44,33.98-24.32,14.37-9.87,29.38-18.69,45.17-26.14,13.59-6.41,27.56-11.67,42.45-14.17C408.74.85,411.3.42,413.86,0c22.29,0,44.58,0,66.87,0,.13,1.16.36,2.32.36,3.49.02,51.82.02,103.64.02,155.46-1.9-.13-3.8-.43-5.7-.36-8.19.3-16.41.39-24.56,1.15-15.71,1.47-29.59,7.7-41.76,17.58-14.03,11.38-28.02,22.83-41.68,34.65-9.98,8.63-19.51,17.79-29.03,26.93-13.68,13.12-27.21,26.39-40.72,39.69-5.81,5.72-11.37,11.69-17.07,17.53-8.47,8.67-16.98,17.3-25.45,25.98-5.35,5.47-10.66,10.98-15.98,16.47-4.79,4.95-9.58,9.91-14.36,14.87-4.37,4.53-8.72,9.09-13.11,13.61-6.86,7.06-13.75,14.09-20.62,21.14-6.16,6.32-12.31,12.66-18.47,18.99-.17.17-.27.41-.4.61-2.08,1.83-4.22,3.59-6.21,5.5-5.66,5.41-11.25,10.89-16.87,16.34-5.8,5.63-11.6,11.26-17.4,16.89-5.5,5.33-11,10.66-16.5,16-5.98,5.81-11.96,11.62-17.94,17.43-5.5,5.34-11.01,10.65-16.5,16-7.78,7.59-15.52,15.22-23.32,22.79-4.59,4.46-9.24,8.84-13.87,13.25Z"/>
          <path style={getColor("cls-1")} d="m533.47,1079.56c14.07-.14,27.29-4.13,40.16-9.3,8.36-3.36,16.67-7,24.53-11.36,11.75-6.52,23.47-13.24,34.51-20.87,16.76-11.59,33.16-23.74,49.29-36.2,11.54-8.92,22.49-18.61,33.5-28.2,10.39-9.05,20.56-18.34,30.71-27.65,8.61-7.9,17.18-15.86,25.54-24.03,19.43-18.96,38.76-38.03,58.05-57.13,7.07-7,13.95-14.2,20.87-21.35,8.61-8.89,17.17-17.82,25.74-26.75,11.43-11.91,22.79-23.89,34.26-35.75,13.25-13.7,26.59-27.3,39.91-40.93.2-.21.53-.3.8-.45v340.41h-417.92c0-.15.02-.3.05-.44Z"/>
          <path style={getColor("cls-1")} d="m413.86,0c-2.56.42-5.13.85-7.69,1.28-14.89,2.5-28.86,7.76-42.45,14.17-15.79,7.45-30.8,16.27-45.17,26.14-11.48,7.89-22.73,16.11-33.98,24.32-6.17,4.5-12.21,9.18-18.15,13.97-5.56,4.49-10.93,9.22-16.36,13.87-6.2,5.32-12.39,10.65-18.54,16.03-4.74,4.14-9.44,8.34-14.09,12.57-6.39,5.82-12.76,11.66-19.08,17.56-6.47,6.05-12.89,12.15-19.29,18.27-5.15,4.92-10.28,9.87-15.37,14.86-13.52,13.28-27.06,26.54-40.49,39.91-7.34,7.3-14.49,14.77-21.71,22.18-4.62,4.75-9.21,9.54-13.81,14.32-4.25,4.42-8.51,8.83-12.75,13.26-7.43,7.76-14.85,15.53-22.28,23.28-7.9,8.25-15.78,16.52-23.73,24.73-7.32,7.56-14.71,15.05-22.06,22.58-2.14,2.19-4.26,4.38-6.39,6.58-.15.08-.31.09-.47.03V0C137.95,0,275.91,0,413.86,0Z"/>
          <path style={getColor("cls-3")} d="m481.12,158.95c0-51.82,0-103.64-.02-155.46,0-1.16-.24-2.32-.36-3.49,1.18,0,2.36,0,3.55,0,2.54.33,5.08.78,7.63.99,26.86,2.21,53.15,7.26,78.85,15.45,32.31,10.29,61.98,25.63,88.79,46.38,6.97,5.4,13.54,11.34,20.04,17.31,22.78,20.94,41.13,45.38,56.36,72.22,11.34,19.99,20.88,40.81,28.13,62.62,3.97,11.95,7.71,23.99,10.99,36.14,5.35,19.85,9.26,40.03,11.92,60.42,1.56,11.91,2.66,23.89,3.71,35.86.62,7.03.76,14.11,1.05,21.17.23,5.71.33,11.42.53,17.13.13,3.94.31,7.88.52,11.81.03.55.35,1.08.54,1.63-3.56-3.1-7.12-6.2-10.68-9.3-.52-.43-1.04-.87-1.57-1.3l.03.03c-.18-.18-.36-.36-.54-.53l.03.03c-.18-.18-.36-.36-.54-.54l.03.03c-.18-.18-.36-.35-.54-.53l.02.02c-.37-.6-.63-1.31-1.11-1.8-6.71-6.65-13.43-13.28-20.19-19.88-13.93-13.61-27.83-27.26-41.83-40.79-10.06-9.72-20.2-19.35-30.42-28.9-13.38-12.5-26.87-24.88-40.32-37.3-1.72-1.59-3.45-3.16-5.21-4.69-5.15-4.48-10.35-8.91-15.48-13.41-4.51-3.95-8.89-8.06-13.46-11.94-5.56-4.71-11.26-9.25-16.91-13.85-4.87-3.97-9.69-7.99-14.66-11.84-5.6-4.34-11.19-8.72-17.03-12.72-6.6-4.51-13.2-9.15-20.29-12.75-11.92-6.06-24.67-10.16-37.59-13.51-7.87-2.04-15.97-3.17-23.97-4.71Z"/>
          <path style={getColor("cls-4")} d="m533.47,1079.56c-.03.15-.05.29-.05.44h-62.81c0-.16-.01-.31-.03-.47,20.97,0,41.93.02,62.9.03Z"/>
          <path style={getColor("cls-7")} d="m0,339.91c.16.06.32.06.47-.03-.01,5.77-.02,11.53-.03,17.3-.15.01-.3,0-.44-.05v-17.22Z"/>
          <path style={getColor("cls-3")} d="m470.16,920.94c.02,1.01.06,2.02.06,3.03,0,51.8,0,103.61,0,155.41-7.68-.63-15.38-1.15-23.05-1.92-16.69-1.68-33.12-4.85-49.33-9.1-15.52-4.07-30.76-9.04-45.4-15.65-7.39-3.34-14.86-6.57-21.97-10.45-12.46-6.78-24.58-14.17-35.71-23.02-5.77-4.59-11.52-9.22-17.09-14.05-5.18-4.5-10.4-9.03-15.07-14.04-6.7-7.19-12.97-14.79-19.32-22.3-10.27-12.15-18.98-25.42-26.74-39.23-6.44-11.46-12.1-23.38-17.69-35.29-8.55-18.24-14.83-37.34-20.31-56.68-5.39-19.01-9.65-38.31-12.35-57.89-2.1-15.24-3.82-30.53-5.5-45.82-.66-6.01-.9-12.08-1.1-18.14-.39-11.33-.62-22.67-.92-34,3.05,2.31,6.12,4.6,9.12,6.96.89.7,1.6,1.61,2.4,2.43.18.26.37.53.55.79l-.03-.04c.18.18.36.36.54.54l-.03-.03c.35.35.7.69,1.05,1.04,0,0-.03-.03-.03-.03.28.4.51.86.85,1.2,10.75,10.61,21.49,21.23,32.29,31.79,11.46,11.2,22.94,22.37,34.5,33.47,9.6,9.23,19.27,18.39,29,27.48,10.95,10.23,21.95,20.41,33.03,30.49,9.15,8.32,18.27,16.69,27.73,24.64,16.18,13.59,32.38,27.18,49.01,40.19,12.92,10.11,26.17,19.98,42.15,25.04,11.3,3.58,22.61,7.17,34.02,10.38,4.98,1.4,10.22,1.89,15.35,2.79Z"/>
          <path style={getColor("cls-5")} d="m170.19,691.19c-.79-.82-1.51-1.73-2.4-2.43-3.01-2.36-6.08-4.64-9.12-6.96-2.65-2.54-5.25-5.15-7.97-7.62-24.58-22.33-46.62-46.92-66.09-73.83-15.64-21.62-29.2-44.47-41.02-68.37,4.63-4.41,9.28-8.8,13.87-13.25,7.79-7.57,15.54-15.2,23.32-22.79,5.49-5.35,11-10.67,16.5-16,5.98-5.81,11.96-11.62,17.94-17.43,5.5-5.34,11-10.66,16.5-16,5.8-5.63,11.6-11.26,17.4-16.89,5.62-5.45,11.22-10.93,16.87-16.34,2-1.91,4.14-3.67,6.21-5.5,1.16,3.3,2.36,6.6,3.49,9.91,6.48,18.96,14.93,37,25,54.31,11.94,20.5,25.97,39.47,41.86,57.07,4.61,5.11,9.49,9.97,14.24,14.95.85.91,1.64,1.9,2.57,2.72,7.15,6.28,14.12,12.8,21.56,18.73,10.63,8.47,21.38,16.83,32.51,24.63,9.97,6.99,20.52,13.16,30.81,19.7,2.87,1.82,5.63,3.83,8.6,5.47,4.14,2.28,8.44,4.29,12.66,6.42,4.96,2.5,9.91,5.02,14.87,7.53,4.27,2.16,8.55,4.31,12.82,6.47-4.81,5.05-9.59,10.13-14.45,15.14-7.62,7.85-15.3,15.65-22.95,23.48-7.36,7.52-14.71,15.05-22.07,22.57-6.01,6.14-12.01,12.29-18.02,18.43-8.32,8.49-16.65,16.97-24.95,25.47-4.41,4.52-8.83,9.05-13.12,13.69-1.27,1.38-2.33,1.46-3.91.68-7.68-3.82-15.47-7.44-23.1-11.35-27.14-13.9-52.48-30.59-76.37-49.52-.55-.43-1.34-.55-2.02-.82,0,0,.03.03.03.03-.35-.35-.7-.69-1.05-1.04l.03.03c-.18-.18-.36-.36-.54-.54l.03.04c-.18-.26-.37-.53-.55-.79Z"/>
          <path style={getColor("cls-5")} d="m782.66,389.83c3.56,3.1,7.12,6.2,10.68,9.3,11.09,10.56,22.62,20.71,33.19,31.78,23.13,24.22,43.54,50.64,60.65,79.48,7.19,12.11,13.54,24.71,20.27,37.09-.46.36-.97.67-1.39,1.08-7.77,7.6-15.53,15.22-23.3,22.82-7.23,7.07-14.46,14.14-21.7,21.19-5.67,5.52-11.36,11.03-17.04,16.54-5.8,5.63-11.6,11.26-17.4,16.89-5.68,5.51-11.36,11.03-17.04,16.54-5.62,5.45-11.22,10.93-16.87,16.35-4.49,4.31-9.03,8.56-13.55,12.84-1.47-4.1-2.96-8.2-4.42-12.3-7.07-19.81-16.02-38.74-27.17-56.57-5.94-9.49-12.35-18.7-18.82-27.85-3.72-5.26-7.88-10.22-12.01-15.17-2.93-3.51-6.12-6.82-9.23-10.18-4.14-4.47-8.32-8.91-12.48-13.37-6.38-5.9-12.51-12.09-19.19-17.62-8.64-7.17-17.66-13.89-26.63-20.65-5.48-4.12-11.04-8.17-16.82-11.86-10.83-6.9-21.65-13.87-32.84-20.15-12.6-7.08-25.62-13.43-38.43-20.14-1.05-.55-1.92-1.43-2.87-2.15,1.4-1.65,2.72-3.39,4.22-4.94,7.56-7.79,15.17-15.53,22.76-23.3,7.41-7.58,14.82-15.16,22.23-22.73,8.24-8.42,16.48-16.85,24.75-25.25,3.71-3.77,7.54-7.42,11.26-11.19,9.89-10.02,19.75-20.07,29.62-30.12.82-.83,1.59-1.7,2.56-2.75,8.9,4.42,17.77,8.68,26.51,13.19,22.93,11.84,44.58,25.73,65.18,41.26,4.07,3.07,8.18,6.08,12.27,9.12,0,0-.02-.02-.02-.02.18.18.36.35.54.53,0,0-.03-.03-.03-.03.18.18.36.36.54.54,0,0-.03-.03-.03-.03.18.18.36.36.54.53,0,0-.03-.03-.03-.03.52.43,1.04.87,1.57,1.3Z"/>
          <path style={getColor("cls-1")} d="m695.02,536.32c4.16,4.45,8.34,8.89,12.48,13.37,3.11,3.36,6.3,6.66,9.23,10.18,4.13,4.95,8.3,9.91,12.01,15.17,6.47,9.15,12.88,18.35,18.82,27.85,11.15,17.83,20.1,36.76,27.17,56.57,1.46,4.1,2.95,8.2,4.42,12.3-5.69,5.75-11.41,11.47-17.07,17.24-5.18,5.28-10.3,10.62-15.43,15.94-4.31,4.47-8.61,8.94-12.92,13.42-4.6,4.77-9.2,9.55-13.81,14.32-4.61,4.78-9.2,9.56-13.82,14.32-6.56,6.75-13.11,13.52-19.72,20.22-16.28,16.49-32.58,32.96-48.91,49.41-6.3,6.35-12.59,12.7-19.03,18.9-8.72,8.38-17.45,16.77-26.43,24.88-10.62,9.59-21.33,19.09-32.31,28.26-11.74,9.8-23.56,19.68-37.71,25.91-5.28,2.33-11.08,3.64-16.78,4.78-9.17,1.82-18.49,2.43-27.85,2.1-2.41-.09-4.81-.33-7.21-.49-5.12-.9-10.36-1.39-15.35-2.79-11.41-3.22-22.71-6.8-34.02-10.38-15.98-5.06-29.23-14.93-42.15-25.04-16.64-13.02-32.84-26.6-49.01-40.19-9.46-7.95-18.58-16.32-27.73-24.64-11.08-10.08-22.08-20.26-33.03-30.49-9.73-9.09-19.4-18.25-29-27.48-11.55-11.1-23.04-22.27-34.5-33.47-10.8-10.56-21.54-21.18-32.29-31.79-.34-.34-.57-.8-.85-1.2.68.27,1.48.39,2.02.82,23.88,18.93,49.23,35.61,76.37,49.52,7.64,3.91,15.42,7.53,23.1,11.35,1.57.78,2.63.7,3.91-.68,4.29-4.64,8.71-9.17,13.12-13.69,8.3-8.51,16.64-16.98,24.95-25.47,6.01-6.14,12.01-12.29,18.02-18.43,7.36-7.52,14.71-15.05,22.07-22.57,7.65-7.82,15.33-15.62,22.95-23.48,4.86-5.01,9.63-10.09,14.45-15.14,3.38-3.48,6.74-6.98,10.14-10.44,10.45-10.63,20.9-21.26,31.37-31.87,3.97-4.02,8-7.98,11.98-12,7.63-7.71,15.25-15.44,22.87-23.17h.64c.01.94.04,1.87.04,2.79,0,65.26,0,130.51,0,195.77,0,.97,0,1.94,0,2.91l.89.54c.64-.86,1.19-1.82,1.95-2.57,73.05-73.08,146.11-146.14,219.19-219.19.81-.81,1.82-1.43,2.74-2.13Z"/>
          <path style={getColor("cls-1")} d="m779.6,387.05c-4.09-3.04-8.2-6.05-12.27-9.12-20.6-15.53-42.24-29.43-65.18-41.26-8.74-4.51-17.61-8.77-26.51-13.19-.97,1.05-1.75,1.92-2.56,2.75-9.87,10.05-19.73,20.1-29.62,30.12-3.72,3.77-7.55,7.42-11.26,11.19-8.27,8.39-16.51,16.82-24.75,25.25-7.41,7.57-14.82,15.16-22.23,22.73-7.59,7.76-15.2,15.5-22.76,23.3-1.5,1.55-2.82,3.29-4.22,4.94-6.9,7.02-13.81,14.04-20.71,21.07-9.86,10.03-19.71,20.08-29.6,30.09-4.33,4.38-8.78,8.65-13.09,13.05-4,4.08-7.9,8.28-11.85,12.41-.46.48-1.04.86-1.88,1.55,0-1.55,0-2.56,0-3.57,0-63.57,0-127.14,0-190.71,0-2.45-.03-4.9-.05-7.34-.28-.07-.57-.14-.85-.21-.13.11-.26.21-.38.33-73.1,73.07-146.21,146.14-219.3,219.23-1.35,1.35-2.47,2.91-3.7,4.38-4.76-4.98-9.63-9.84-14.24-14.95-15.89-17.59-29.92-36.56-41.86-57.07-10.08-17.31-18.52-35.35-25-54.31-1.13-3.31-2.32-6.61-3.49-9.91.13-.21.24-.44.4-.61,6.15-6.33,12.3-12.67,18.47-18.99,6.87-7.05,13.76-14.08,20.62-21.14,4.39-4.52,8.74-9.07,13.11-13.61,4.78-4.96,9.57-9.92,14.36-14.87,5.32-5.5,10.63-11,15.98-16.47,8.47-8.67,16.98-17.31,25.45-25.98,5.7-5.83,11.26-11.8,17.07-17.53,13.5-13.3,27.04-26.57,40.72-39.69,9.52-9.14,19.06-18.3,29.03-26.93,13.66-11.82,27.65-23.27,41.68-34.65,12.17-9.88,26.05-16.11,41.76-17.58,8.15-.76,16.37-.85,24.56-1.15,1.89-.07,3.8.23,5.7.36,8,1.54,16.11,2.66,23.97,4.71,12.92,3.36,25.67,7.46,37.59,13.51,7.09,3.61,13.7,8.25,20.29,12.75,5.85,3.99,11.43,8.37,17.03,12.72,4.96,3.85,9.78,7.88,14.66,11.84,5.65,4.6,11.35,9.14,16.91,13.85,4.58,3.88,8.95,7.99,13.46,11.94,5.14,4.5,10.33,8.93,15.48,13.41,1.76,1.53,3.5,3.1,5.21,4.69,13.45,12.42,26.94,24.8,40.32,37.3,10.22,9.55,20.36,19.18,30.42,28.9,14,13.54,27.91,27.18,41.83,40.79,6.75,6.6,13.48,13.23,20.19,19.88.49.48.75,1.19,1.11,1.8Z"/>
          <path style={getColor("cls-1")} d="m782.66,389.83c-.52-.43-1.04-.87-1.57-1.3.52.43,1.04.87,1.57,1.3Z"/>
          <path style={getColor("cls-1")} d="m780.62,388.06c-.18-.18-.36-.36-.54-.54.18.18.36.36.54.54Z"/>
          <path style={getColor("cls-1")} d="m780.11,387.55c-.18-.18-.36-.35-.54-.53.18.18.36.35.54.53Z"/>
          <path style={getColor("cls-1")} d="m781.13,388.56c-.18-.18-.36-.36-.54-.53.18.18.36.36.54.53Z"/>
          <path style={getColor("cls-1")} d="m171.22,692.45c.35.35.7.69,1.05,1.04-.35-.35-.7-.69-1.05-1.04Z"/>
          <path style={getColor("cls-1")} d="m170.19,691.19l.55.79c-.18-.26-.37-.53-.55-.79Z"/>
          <path style={getColor("cls-1")} d="m170.71,691.94c.18.18.36.36.54.54-.18-.18-.36-.36-.54-.54Z"/>
          <path style={getColor("cls-3")} d="m256.8,544.03c1.23-1.46,2.36-3.03,3.7-4.38,73.09-73.09,146.19-146.16,219.3-219.23.12-.12.26-.22.38-.33.28.07.57.14.85.21.02,2.45.05,4.9.05,7.34,0,63.57,0,127.14,0,190.71,0,1.01,0,2.02,0,3.57.85-.69,1.42-1.07,1.88-1.55,3.96-4.13,7.85-8.33,11.85-12.41,4.31-4.4,8.76-8.66,13.09-13.05,9.89-10.01,19.73-20.06,29.6-30.09,6.9-7.02,13.81-14.04,20.71-21.07.95.73,1.83,1.6,2.87,2.15,12.81,6.71,25.83,13.06,38.43,20.14,11.19,6.29,22.01,13.25,32.84,20.15,5.78,3.68,11.34,7.73,16.82,11.86,8.97,6.75,17.99,13.48,26.63,20.65,6.68,5.54,12.82,11.73,19.19,17.62-.92.71-1.92,1.32-2.74,2.13-73.08,73.05-146.14,146.12-219.19,219.19-.75.75-1.3,1.71-1.95,2.57l-.89-.54c0-.97,0-1.94,0-2.91,0-65.26,0-130.51,0-195.77,0-.93-.03-1.86-.04-2.79.02-.26.05-.51.07-.77-.24.25-.48.51-.72.76-7.62,7.72-15.23,15.46-22.87,23.17-3.98,4.02-8.01,7.97-11.98,12-10.47,10.61-20.92,21.24-31.37,31.87-3.4,3.46-6.76,6.96-10.14,10.44-4.27-2.15-8.55-4.31-12.82-6.47-4.96-2.51-9.91-5.03-14.87-7.53-4.23-2.13-8.52-4.14-12.66-6.42-2.97-1.63-5.73-3.64-8.6-5.47-10.29-6.54-20.84-12.71-30.81-19.7-11.12-7.8-21.88-16.16-32.51-24.63-7.44-5.93-14.41-12.45-21.56-18.73-.93-.82-1.72-1.81-2.57-2.72Z"/>
          <path style={getColor("cls-2")} d="m469.56,558.2c.24-.25.48-.51.72-.76-.03.26-.05.51-.07.77-.21,0-.43,0-.64,0Z"/>
          <path style={getColor("cls-2")} d="m481.03,320.29c-.28-.07-.57-.14-.85-.21.28.07.57.14.85.21Z"/>
        </svg>
      </div>;
  }
  